<template>
  <div class="card grid grid-cols-12">
    <div class="col-span-12 md:col-span-5">
      <div class="w-full h-full  p-5">
        <div>
          <button
            class="flex justify-center items-center gap-1 text-sm text-gray-600 px-2 md:px-3 py-1 bg-orange-100 rounded-full my-2 "
          >
            <img
              class="w-5"
              src="@/assets/icon/offer/pass/pass_ribon.svg"
              alt="unlock"
            />
            <span class="pl-1" v-if="passCategory === 0"
              >{{ currencySymbol }}{{ passPrice }}
              {{
                $t('components.subscriptionManagement.addEdit.card.creditPass')
              }}</span
            >
            <span class="pl-1" v-if="passCategory === 1"
              >{{ minuteAmountOfPass }}
              {{
                $t('components.subscriptionManagement.addEdit.card.minutePass')
              }}</span
            >
          </button>
        </div>
        <div class="my-3">
          <h1 class="uppercase font-bold flex items-center">
            <span class="text-orange-500 text-3xl "
              >{{ currencySymbol }}{{ passPrice }}</span
            >
          </h1>
        </div>
        <!-- <div>
          <button
            class="flex justify-center items-center gap-1 text-sm text-gray-500 "
          >
            <img
              class="w-3"
              src="@/assets/icon/offer/pass/pass_tag.svg"
              alt="pass_tag"
            />
            <span class="pl-1">{{
              $t('components.subscriptionManagement.addEdit.card.saveUpto50')
            }}</span>
          </button>
        </div> -->
      </div>
    </div>
    <div class="col-span-12 md:col-span-7">
      <div class="w-full h-full top-border p-5">
        <h2 class="font-semibold text-lg block mb-1 lg:hidden">
          {{ $t('components.subscriptionManagement.addEdit.card.details') }}
        </h2>
        <p
          v-if="
            passCategory === 0 &&
              isUnlockDiscountApplied &&
              !!unlockDiscount &&
              unlockDiscount > 99.99
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate">{{
            $t(
              'components.subscriptionManagement.addEdit.card.freeUnlockCharge'
            )
          }}</span>
        </p>
        <p
          v-if="
            passCategory === 0 &&
              isUnlockDiscountApplied &&
              !!unlockDiscount &&
              unlockDiscount <= 99.99
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{ unlockDiscount }}%
            {{
              $t(
                'components.subscriptionManagement.addEdit.card.discountUnlockCharge'
              )
            }}</span
          >
        </p>
        <p
          v-if="
            passCategory === 0 &&
              isReservationDiscountApplied &&
              !!reservationDiscount &&
              reservationDiscount > 99.99
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate">{{
            $t(
              'components.subscriptionManagement.addEdit.card.freeReservationCharge'
            )
          }}</span>
        </p>
        <p
          v-if="
            passCategory === 0 &&
              isReservationDiscountApplied &&
              !!reservationDiscount &&
              reservationDiscount <= 99.99
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{ reservationDiscount }}%
            {{
              $t(
                'components.subscriptionManagement.addEdit.card.discountReservationCharge'
              )
            }}</span
          >
        </p>
        <p
          v-if="
            passCategory === 0 &&
              isMinuteChargeDiscountApplied &&
              !!perMinuteChargeDiscount &&
              perMinuteChargeDiscount > 99.99
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate">{{
            $t(
              'components.subscriptionManagement.addEdit.card.freePerMinuteCharge'
            )
          }}</span>
        </p>
        <p
          v-if="
            passCategory === 0 &&
              isMinuteChargeDiscountApplied &&
              !!perMinuteChargeDiscount &&
              perMinuteChargeDiscount <= 99.99
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{ perMinuteChargeDiscount }}%
            {{
              $t(
                'components.subscriptionManagement.addEdit.card.discountPerMinuteCharge'
              )
            }}</span
          >
        </p>
        <p
          v-if="
            passCategory === 0 &&
              isFreeMinutesPerRideApplied &&
              !!freeMinutesPerRide &&
              freeMinutesPerRide > 0
          "
          class="flex gap-2 text-sm mb-1 "
        >
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >First {{ freeMinutesPerRide }}
            {{
              freeMinutesPerRide > 1
                ? $t('components.subscriptionManagement.addEdit.card.minutes')
                : $t('components.subscriptionManagement.addEdit.card.minute')
            }}
            {{
              $t('components.subscriptionManagement.addEdit.card.free')
            }}</span
          >
        </p>
        <p v-if="passCategory === 1" class="flex gap-2 text-sm mb-1 ">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate">{{
            $t('components.subscriptionManagement.addEdit.card.unlimitedRide')
          }}</span>
        </p>
        <p class="flex gap-2 text-sm mb-1">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{
              $t('components.subscriptionManagement.addEdit.card.useWithin')
            }}
            {{ days }}
            {{
              days > 1
                ? $t('components.subscriptionManagement.addEdit.card.days')
                : $t('components.subscriptionManagement.addEdit.card.day')
            }}</span
          >
        </p>
        <p class="flex gap-2 text-sm mb-1">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{ $t('components.subscriptionManagement.addEdit.card.startAt') }}
            {{ passStartDate }}</span
          >
        </p>
        <p class="flex gap-2 text-sm mb-1">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{
              $t('components.subscriptionManagement.addEdit.card.expireAt')
            }}
            {{ passExpiredDate }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PassCard',
  props: {
    minuteAmountOfPass: {
      type: Number,
      default: 60,
    },
    currencySymbol: {
      type: String,
      default: '--',
    },
    passPrice: {
      type: Number,
      default: 19.99,
    },
    days: {
      type: Number,
      default: 3,
    },
    passCategory: {
      type: Number,
      default: 0,
    },
    isUnlockDiscountApplied: {
      type: Boolean,
    },
    unlockDiscount: {
      type: Number,
      default: 0,
    },
    isReservationDiscountApplied: {
      type: Boolean,
    },
    reservationDiscount: {
      type: Number,
      default: 0,
    },
    isMinuteChargeDiscountApplied: {
      type: Boolean,
    },
    perMinuteChargeDiscount: {
      type: Number,
      default: 0,
    },
    isFreeMinutesPerRideApplied: {
      type: Boolean,
    },
    freeMinutesPerRide: {
      type: Number,
      default: 0,
    },
    passStartDate: {
      type: String,
      default: '--',
    },
    passExpiredDate: {
      type: String,
      default: '--',
    },
  },
  computed: {
    getDiscountedText() {
      if (
        this.isUnlockDiscountApplied &&
        Number(this.unlockDiscount) &&
        this.unlockDiscount > 0
      ) {
        if (this.unlockDiscount < 100) {
          return `Unlock ${this.unlockDiscount}% OFF`
        } else {
          return 'Free Unlock'
        }
      } else if (
        this.isReservationDiscountApplied &&
        Number(this.reservationDiscount) &&
        this.reservationDiscount > 0
      ) {
        if (this.reservationDiscount < 100) {
          return `Reservation ${this.reservationDiscount}% OFF`
        } else {
          return 'Free Reservation'
        }
      } else if (
        this.isMinuteChargeDiscountApplied &&
        Number(this.perMinuteChargeDiscount) &&
        this.perMinuteChargeDiscount > 0
      ) {
        if (this.perMinuteChargeDiscount < 100) {
          return `Per Minute ${this.perMinuteChargeDiscount}% OFF`
        } else {
          return 'Free Per Minute'
        }
      } else {
        return 'Free Unlock'
      }
    },
  },
  methods: {},
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply bg-white rounded-lg;
}

.circle-cut {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 99999px;
  @apply bg-gray-100;
}
.circle-cut.circle-top {
  top: -177px;
}
.circle-cut.circle-bottom {
  bottom: -177px;
}
.divider {
  width: 0.5px;
  height: 100%;
}
.referral-code {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  text-transform: uppercase;
  color: #676773;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.code {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e39;
}
.copy {
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #da35a1;
  cursor: pointer;
}
.h-122px {
  height: 122px;
}
.w-122px {
  width: 118px;
}
.mt-31px {
  margin-top: 35px;
}

.top-border {
  @media (max-width: 767px) {
    border-top: 1px solid;
    @apply border-gray-200;
  }
}
</style>
