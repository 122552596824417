var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card grid grid-cols-12"},[_c('div',{staticClass:"col-span-12 md:col-span-5"},[_c('div',{staticClass:"w-full h-full  p-5"},[_c('div',[_c('button',{staticClass:"flex justify-center items-center gap-1 text-sm text-gray-600 px-2 md:px-3 py-1 bg-orange-100 rounded-full my-2 "},[_c('img',{staticClass:"w-5",attrs:{"src":require("@/assets/icon/offer/pass/pass_ribon.svg"),"alt":"unlock"}}),(_vm.passCategory === 0)?_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.currencySymbol)+_vm._s(_vm.passPrice)+" "+_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.creditPass')))]):_vm._e(),(_vm.passCategory === 1)?_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.minuteAmountOfPass)+" "+_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.minutePass')))]):_vm._e()])]),_c('div',{staticClass:"my-3"},[_c('h1',{staticClass:"uppercase font-bold flex items-center"},[_c('span',{staticClass:"text-orange-500 text-3xl "},[_vm._v(_vm._s(_vm.currencySymbol)+_vm._s(_vm.passPrice))])])])])]),_c('div',{staticClass:"col-span-12 md:col-span-7"},[_c('div',{staticClass:"w-full h-full top-border p-5"},[_c('h2',{staticClass:"font-semibold text-lg block mb-1 lg:hidden"},[_vm._v(" "+_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.details'))+" ")]),(
          _vm.passCategory === 0 &&
            _vm.isUnlockDiscountApplied &&
            !!_vm.unlockDiscount &&
            _vm.unlockDiscount > 99.99
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t( 'components.subscriptionManagement.addEdit.card.freeUnlockCharge' )))])]):_vm._e(),(
          _vm.passCategory === 0 &&
            _vm.isUnlockDiscountApplied &&
            !!_vm.unlockDiscount &&
            _vm.unlockDiscount <= 99.99
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.unlockDiscount)+"% "+_vm._s(_vm.$t( 'components.subscriptionManagement.addEdit.card.discountUnlockCharge' )))])]):_vm._e(),(
          _vm.passCategory === 0 &&
            _vm.isReservationDiscountApplied &&
            !!_vm.reservationDiscount &&
            _vm.reservationDiscount > 99.99
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t( 'components.subscriptionManagement.addEdit.card.freeReservationCharge' )))])]):_vm._e(),(
          _vm.passCategory === 0 &&
            _vm.isReservationDiscountApplied &&
            !!_vm.reservationDiscount &&
            _vm.reservationDiscount <= 99.99
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.reservationDiscount)+"% "+_vm._s(_vm.$t( 'components.subscriptionManagement.addEdit.card.discountReservationCharge' )))])]):_vm._e(),(
          _vm.passCategory === 0 &&
            _vm.isMinuteChargeDiscountApplied &&
            !!_vm.perMinuteChargeDiscount &&
            _vm.perMinuteChargeDiscount > 99.99
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t( 'components.subscriptionManagement.addEdit.card.freePerMinuteCharge' )))])]):_vm._e(),(
          _vm.passCategory === 0 &&
            _vm.isMinuteChargeDiscountApplied &&
            !!_vm.perMinuteChargeDiscount &&
            _vm.perMinuteChargeDiscount <= 99.99
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.perMinuteChargeDiscount)+"% "+_vm._s(_vm.$t( 'components.subscriptionManagement.addEdit.card.discountPerMinuteCharge' )))])]):_vm._e(),(
          _vm.passCategory === 0 &&
            _vm.isFreeMinutesPerRideApplied &&
            !!_vm.freeMinutesPerRide &&
            _vm.freeMinutesPerRide > 0
        )?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v("First "+_vm._s(_vm.freeMinutesPerRide)+" "+_vm._s(_vm.freeMinutesPerRide > 1 ? _vm.$t('components.subscriptionManagement.addEdit.card.minutes') : _vm.$t('components.subscriptionManagement.addEdit.card.minute'))+" "+_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.free')))])]):_vm._e(),(_vm.passCategory === 1)?_c('p',{staticClass:"flex gap-2 text-sm mb-1 "},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.unlimitedRide')))])]):_vm._e(),_c('p',{staticClass:"flex gap-2 text-sm mb-1"},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.useWithin'))+" "+_vm._s(_vm.days)+" "+_vm._s(_vm.days > 1 ? _vm.$t('components.subscriptionManagement.addEdit.card.days') : _vm.$t('components.subscriptionManagement.addEdit.card.day')))])]),_c('p',{staticClass:"flex gap-2 text-sm mb-1"},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.startAt'))+" "+_vm._s(_vm.passStartDate))])]),_c('p',{staticClass:"flex gap-2 text-sm mb-1"},[_c('img',{staticClass:"w-3",attrs:{"src":require("@/assets/icon/offer/pass/mark.svg"),"alt":"mark"}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('components.subscriptionManagement.addEdit.card.expireAt'))+" "+_vm._s(_vm.passExpiredDate))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }