<template>
  <ContentSection :spacing="false" class="mt-2">
    <section class="content-spacing">
      <div class="flex items-center justify-between">
        <TitlePlus
          :title="
            $t('components.subscriptionManagement.details.passHistory.headline')
          "
          :hidePlus="true"
        />
        <DateRangePicker
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>

      <div class="summary-card-container">
        <SummaryCard
          :title="
            $t(
              'components.subscriptionManagement.details.passHistory.summary.totalSubscriptionUsers'
            )
          "
          :value="getSummaryUsers"
          variant="gray"
        />

        <SummaryCard
          :title="
            $t(
              'components.subscriptionManagement.details.passHistory.summary.totalSubscriptionEarnings'
            )
          "
          :value="getSummaryEarnings"
          variant="gray"
        />
      </div>
    </section>

    <FSTable
      :fst-id="`passDetailsHistory`"
      :headers="getTableHeaders"
      :endpoint="
        `/dashboard/invoices/?transaction_type=CREDIT&package=${packageId}`
      "
      :qso="{ prepend: '&', append: '' }"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <template v-for="(item, itemIndex) in data">
          <template v-if="getTableMode === 'full'">
            <FSTableRow :key="`fs-table-row-${itemIndex}`" text-fallback-always>
              <FSTableRowItem :text="item.id" :truncate="-5" />

              <FSTableRowItem
                :text="item.user.full_name"
                :to="{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.user.id },
                }"
                target="_blank"
              />

              <FSTableRowItem :text="item.date" date />

              <FSTableRowItem>
                <div v-if="item.payment_method_details">
                  <i
                    :class="
                      getPaymentCardIconClass(item.payment_method_details)
                    "
                  ></i>
                  <span class="ml-1"
                    >**{{ item.payment_method_details.number }}</span
                  >
                </div>
                <div v-else>{{ item.payment_method }}</div>
              </FSTableRowItem>

              <!-- <FSTableRowItem :text="getInvoiceStatus(item.status)" /> -->
              <FSTableRowItem>
                <x-status
                  :text="getPassStatus(item.status)"
                  :variant="getPassStatusBadge(item.status)"
                  profile="pass"
                />
              </FSTableRowItem>
            </FSTableRow>
          </template>
          <template v-if="getTableMode === 'responsive'">
            <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
              <FSTableRowItem>
                <div
                  class="col-span-1 focus:text-gray-400"
                  @click="toggle(itemIndex)"
                >
                  <i
                    class="fas fa-minus-circle"
                    style="color:#d90a20;"
                    v-if="opened.includes(itemIndex)"
                  ></i>
                  <i class="fas fa-plus-circle" v-else></i>
                </div>
              </FSTableRowItem>
              <FSTableRowItem
                :text="item.user.full_name"
                :to="{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.user.id },
                }"
                target="_blank"
              />
              <FSTableRowItem>
                <div v-if="item.payment_method_details">
                  <i
                    :class="
                      getPaymentCardIconClass(item.payment_method_details)
                    "
                  ></i>
                  <span class="ml-1"
                    >**{{ item.payment_method_details.number }}</span
                  >
                </div>
                <div v-else>{{ item.payment_method }}</div>
              </FSTableRowItem>
              <FSTableRowItem>
                <x-status
                  :text="getPassStatus(item.status)"
                  :variant="getPassStatusBadge(item.status)"
                  profile="pass"
                />
              </FSTableRowItem>
            </FSTableRow>
            <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
              <td colspan="10">
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.subscriptionManagement.details.passHistory.table.columns.invoiceId'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ item.id | onlyLastFive }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.subscriptionManagement.details.passHistory.table.columns.availedDate'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      getFormattedDateTime(item.date, 'D MMM YYYY, h:mm:ss a')
                    }}
                  </div>
                </div>
              </td>
            </FSTableRow>
          </template>
        </template>
      </template>
    </FSTable>
  </ContentSection>
</template>

<script>
import TitlePlus from '@/components/ui/TitlePlus.vue'
import ContentSection from '@/components/layout/ContentSection'
import SummaryCard from '@/components/cards/SummaryCard'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getInvoiceStatus } from '@/utils'
import XStatus from '@/components/badge/XStatus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  // FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'Vehicles',
  components: {
    ContentSection,
    TitlePlus,
    SummaryCard,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    // FSTableFilter,
  },
  props: {
    packageId: {
      required: false,
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_pass_users: '0.00',
          total_pass_earning: '0.00',
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.invoiceId'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.riderName'
          ),
          width: '20%',
          sort: 'user_id',
        },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.availedDate'
          ),
          width: '20%',
          sort: 'availed_date',
        },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.paymentMethod'
          ),
          width: '15%',
          sort: 'payment',
        },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.status'
          ),
          width: '10%',
          sort: 'status',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.riderName'
          ),
          width: '20%',
          sort: 'user_id',
        },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.paymentMethod'
          ),
          width: '15%',
          sort: 'payment',
        },
        {
          text: this.$t(
            'components.subscriptionManagement.details.passHistory.table.columns.status'
          ),
          width: '10%',
          sort: 'status',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaryUsers() {
      return this.indexMetaData.summary.total_pass_users
    },
    getSummaryEarnings() {
      const symbol =
        this.indexMetaData?.summary?.currency?.symbol || this.currencySymbol
      return `${symbol} ${this.indexMetaData.summary.total_pass_earning}`
    },
  },
  methods: {
    onApplyFilterDateRange,
    getInvoiceStatus,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getDiscountAmount(amount) {
      return this.currencySymbol + ' ' + parseFloat(amount).toFixed(2)
    },
    getPaymentCardIconClass(card) {
      console.log('card-details', card)
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
    getPassStatus(status) {
      const statuses = {
        C: 'Complete',
        P: 'Pending',
      }
      return status in statuses ? statuses[status] : '--'
    },
    getPassStatusBadge(status) {
      const badges = {
        C: 'green',
        P: 'orange',
      }
      return status in badges ? badges[status] : 'gray'
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
