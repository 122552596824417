<template>
  <div>
    <div class="flex w-auto mt-2 md:items-center md:justify-end md:mt-0">
      <t-dropdown variant="smActions">
        <template
          v-slot:trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
        >
          <button
            :class="getButtonClass"
            aria-label="Pass Actions"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <loading
              :height="25"
              :opacity="0"
              color="#fff"
              v-if="lockReq"
              :active="true"
              loader="bars"
              :is-full-page="false"
            />
            <span v-else :class="{ 'pl-3': status === 'Expired' }">
              <i
                v-if="status !== 'Expired'"
                class="ml-2 mr-2 fas fa-chevron-down"
              ></i>
              {{ status }}
            </span>
          </button>
        </template>

        <template v-slot:default="{ hide }">
          <div
            v-if="status !== 'Expired'"
            @click="hide()"
            class="py-1 mt-px bg-white rounded-md shadow-lg"
          >
            <t-dropdown-item
              v-if="status !== 'Active'"
              class="font-medium text-14px"
              @click="handleVehicleLockUnlock(true)"
            >
              {{ $t('components.subscriptionManagement.action.activate') }}
            </t-dropdown-item>
            <t-dropdown-item
              v-if="status !== 'Inactive'"
              class="font-medium text-14px"
              @click="handleVehicleLockUnlock(false)"
            >
              {{ $t('components.subscriptionManagement.action.inactivate') }}
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleActions',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },

  props: {
    currentStatus: {
      type: String,
    },
  },

  watch: {
    currentStatus: {
      immediate: true,
      deep: false,
      handler(v) {
        this.status = v
      },
    },
  },

  data() {
    return {
      lockReq: false,
      status: false,
    }
  },

  computed: {
    getStatusText() {
      return this.status ? 'Active' : 'Inactive'
    },

    getButtonClass() {
      let c = 'pass-action-button emphasize '
      // color
      if (this.status === 'Active') {
        c += 'green '
      }
      if (this.status === 'Inactive') {
        c += 'gray '
      }
      if (this.status === 'Expired') {
        c += 'red '
      }

      return c
    },
  },

  methods: {
    async handleVehicleLockUnlock(status) {
      if (this.lockReq === true) {
        return
      }

      this.lockReq = true
      this.$emit('change', status)
      this.lockReq = false
    },
  },
}
</script>

<style lang="scss" scoped>
.pass-action-button {
  // position: relative;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  color: white;

  // font-size: 14px;
  // font-weight: 500;

  &:focus {
    outline: none;
  }

  // colors
  &.green {
    @apply bg-oGreen;
  }
  &.orange {
    background-color: #ffb100;
  }
  &.gray {
    background-color: #c7c7c7;
  }
  &.red {
    @apply bg-oRed;
  }

  // variants
  &.badge {
    width: 110px;
    height: 20px;
    border-radius: 3px;
  }

  &.emphasize {
    width: 155px;
    @apply flex items-center justify-between shadow-md rounded-full h-35px;
    @apply px-3 py-0 pl-3 text-center cursor-pointer;

    &.inactive {
      @apply bg-gray-300;
    }
  }
}
</style>
